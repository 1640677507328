<template>
  <UiTourDriver ref="driver" :tour="TOURS_IDS.PIN_NOTE" :steps></UiTourDriver>
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS } from '~/constants'

onMounted(() => {
  useMittEvent('tour:pinNote')
})

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

const steps = computed<DriveStep[]>(() => [
  {
    element: '#pin-51294',
    popover: {
      title: '1/2 📌Pinned notes!',
      description: `Now you can pin 5 most important notes. You'll see this icon when you hover over the note.`,
      onNextClick: async () => {
        useMittEvent('tour:pinNotePushPin')
        await nextTick()
        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#activity-51294-true',
    popover: {
      title: '2/2 📌Pinned notes!',
      description: `All pinned notes will be saved in this block. You can collapse it for comfortable work.`,
      onPrevClick: async () => {
        useMittEvent('tour:unpinNote')
        await nextTick()
        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: () => {
        if (driver.value?.driverObj) {
          driver.value.driverObj.destroy()
        }
      },
    },
  },
])

onUnmounted(() => {
  useMittEvent('tour:clearPinNoteMock')
  useMittRemove('tour:pinNote')
})
</script>

<style scoped></style>
